export function convertToEuropeanDate(isoString) {
    const date = new Date(isoString);
    return date.toLocaleDateString('de-DE');
}

export function formatAPIDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`;
}

export const formatDateForApi = (dateString) => {

    console.log(`dateString in formatDateForApi: ${dateString}`);

    if (!dateString || dateString === '') {
        console.log('dateString is empty');
        return '';
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}.${month}.${year}`;


    console.log(`formattedDate in formatDateForApi: ${formattedDate}`);

    return formattedDate;
};
