import React, { useState, useEffect } from 'react';
import FilesForm from '../components/forms/FilesForm';
import getEndpoints from '../api/endpoints';
import LoadingComponent from '../components/layout/LoadingComponent';
import ContentHeading from '../components/layout/ContentHeading';
import { useUserAuth } from '../context/UserAuthContext';
import { Button, Icon, Message, Segment } from 'semantic-ui-react';
import FilesTable from '../components/tables/FilesTable';
import { toast } from 'react-toastify';
import { formatDateForApi } from '../utis/dateConversions';
import { displayDataFromStringOrList } from '../utis/displayUtils';


const NewZipRequest = () => {

    const [lookupData, setLookupData] = useState(null);
    const [filesTableData, setFilesTableData] = useState([]);
    const [loadingFileData, setLoadingFileData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formValuesToSend, setFormValuesToSend] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const { user } = useUserAuth();

    useEffect(() => {
        const fetchLookupData = async () => {
            try {
                const Endpoints = await getEndpoints();
                const data = await Endpoints.getLookupsData();
                setLookupData(data);
            } catch (error) {
                alert('Error fetching lookup data:');
            }
        };

        fetchLookupData();
        console.log('NewZipRequest mounted');
    }, []);

    const onFormSubmit = async (values) => {
        setLoadingFileData(true)
        setFilesTableData([]);

        const dateAdjustedFormValues = {
            ...values,
            lastUpdateInVp: formatDateForApi(values.lastUpdateInVp),
            IAN: values.IAN ? values.IAN.split(',') : []
        }

        setFormValuesToSend(dateAdjustedFormValues);
        console.log('onFormSubmit dateAdjustedFormValues:', dateAdjustedFormValues);
        const Endpoints = await getEndpoints();
        console.log(dateAdjustedFormValues);
        try {
            const data = await Endpoints.getFileSearch(dateAdjustedFormValues);
            setFilesTableData(data);
            if (data.length === 0) {
                toast.warn('Keine Dateien für die angegebenen Kriterien gefunden');
            }
        } catch (error) {
            alert('Error submitting new ziprequest:');
            setFormValuesToSend(null);
        } finally {
            setLoadingFileData(false);
        }
    };

    const getZipRequestFormValues = () => {
        let ianList = [];

        // If formValuesToSend.IAN has a value, add it to the list
        if (formValuesToSend.IAN.length > 0) {
            ianList.push(formValuesToSend.IAN[0]);
        }

        // If selectedRows has items, add their ian values to the list
        if (selectedRows.length > 0) {
            const selectedIanValues = selectedRows.map(row => row.ian);
            ianList = [...ianList, ...selectedIanValues];
        }

        // Remove duplicates
        ianList = [...new Set(ianList)];

        return {
            userName: user.displayName,
            zipRequestFilter: {
                ...formValuesToSend,
                IAN: ianList
            }
        };
    };

    const onRequestZip = async () => {
        console.log('onRequestZip:', getZipRequestFormValues(formValuesToSend));
        setLoading(true);
        try {
            const Endpoints = await getEndpoints();
            const data = await Endpoints.postNewZipRequest(getZipRequestFormValues(formValuesToSend));
            toast.success(`Zip request submitted ${data}`);
        } catch (error) {
            toast.error('Error requesting zip:');
        } finally {
            setLoading(false);
        }
    };

    const onClearForm = () => {
        setFilesTableData([]);
        setFormValuesToSend(null);
    }

    if (!lookupData || loadingFileData || loading) {
        return <LoadingComponent />;
    }

    return (
        <div>
            <ContentHeading title='Dateisuche' />
            {/* <pre>{JSON.stringify(formValues)}</pre> */}
            <Segment padded>
                <FilesForm
                    lookupData={lookupData}
                    onFormSubmit={onFormSubmit}
                    clearForm={onClearForm} />
            </Segment>

            {
                filesTableData.length > 0 &&
                <Segment padded>
                    {/* <pre>selectedRows:{JSON.stringify(selectedRows)}</pre> */}
                    {/* <pre>formValuesToSend:{JSON.stringify(formValuesToSend)}</pre> */}
                    {
                        formValuesToSend &&
                        <Message>
                            <Button
                                color='violet'
                                floated='right'
                                onClick={() => onRequestZip()}> <Icon name='download' />Request Zip</Button>
                            <Message.Header>Suchkriterien</Message.Header>
                            <Message.List>
                                <Message.Item>Einkäufer: {displayDataFromStringOrList(formValuesToSend.buyerShortCode)}</Message.Item>
                                <Message.Item>Charge: {displayDataFromStringOrList(formValuesToSend.charge)}</Message.Item>
                                <Message.Item>Type: {displayDataFromStringOrList(formValuesToSend.type)}</Message.Item>
                                <Message.Item>Last Update In Vp: {displayDataFromStringOrList(formValuesToSend.lastUpdateInVp)}</Message.Item>
                                <Message.Item>Is Date From: {formValuesToSend.isDateFrom ? 'Yes' : 'No'}</Message.Item>
                                <Message.Item>IAN: {displayDataFromStringOrList(formValuesToSend.IAN)}</Message.Item>
                            </Message.List>
                        </Message>
                    }
                    <FilesTable data={filesTableData} setSelectedRows={setSelectedRows} />
                </Segment>
            }
        </div>
    )
};

export default NewZipRequest;
