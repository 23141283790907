import React, { useState } from 'react'
import FileDropZone from '../files/FileDropZone'
import FileDropResult from '../tables/FileDropResult'
import { Button, Divider } from 'semantic-ui-react'
import getEndpoints from '../../api/endpoints'
import Loadingcomponent from '../layout/LoadingComponent'

const NewTranslationDocumentForm = ({ onFileLoaded, onCancel }) => {

    const [droppedFile, setDroppedFile] = useState(null)
    const [loading, setLoading] = useState(false)

    
    const onMakeRequest = async (fileData) => {
        try {
            setLoading(true);
            const ep = await getEndpoints();
            await ep.uploadBriefingDocumentForTranslation(fileData.file);
            onFileLoaded(false);
        } catch (error) {
            onFileLoaded(true);
            console.error(error);
        } finally {
            setLoading(false);  
            setDroppedFile(null); 
        }
    };


    if (loading) return <Loadingcomponent />

    return (
        <div>
            {
                !droppedFile && <>
                    <FileDropZone onFileDrop={(fileData) => setDroppedFile(fileData)} />
                    <div className="my-gap">
                        <Button negative onClick={onCancel} className='my-gap'>Abbrechen</Button>
                    </div>
                </>
            }
            {
                droppedFile && <div className="my-gap"><FileDropResult fileDropResult={droppedFile} onMakeRequest={onMakeRequest} /></div>
            }
        </div>
    )
}

export default NewTranslationDocumentForm