import { useEffect } from "react";
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable, useRowSelect } from "react-table"
import { Button, Icon, Input, Table } from "semantic-ui-react"

function GlobalFilter({
    globalFilter,
    setGlobalFilter,
}) {
    return (
        <Input
            value={globalFilter || ''}
            onChange={e => {
                setGlobalFilter(e.target.value || undefined); // Set the global filter value
            }}
            placeholder={`Suchen...`}
        />
    )
}

const BaseTableGlobalFilter = ({ columns, data, setSelectedRows }) => {
    const tableInstance = useTable({ columns, data, initialState: { pageSize: 20, pageIndex: 0 }, autoResetPage: false },
        useGlobalFilter, useFilters, useSortBy, usePagination, useRowSelect);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        //rows,
        prepareRow,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        gotoPage,
        selectedFlatRows,
        state: { pageIndex, globalFilter },
    } = tableInstance


    useEffect(() => {
        console.log('useEffect running');
        console.log('selectedFlatRows:', selectedFlatRows);
        if (setSelectedRows) {
            console.log('setSelectedRows is defined');
            setSelectedRows(selectedFlatRows.map((row) => row.original));
        } else {
            console.log('setSelectedRows is not defined');
        }
    }, [selectedFlatRows, setSelectedRows]);


    return (
        <div>
            <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <Table celled {...getTableProps()}>
                <Table.Header>
                    {headerGroups.map(headerGroup => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? <Icon name='angle up' /> : <Icon name='angle down' />) : ''}
                                    </span>
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <Table.Row {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                                ))}
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
            <Button.Group>
                <Button icon='angle double left' onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                <Button icon='angle left' onClick={() => previousPage()} disabled={!canPreviousPage} />
                <Button icon='angle right' onClick={() => nextPage()} disabled={!canNextPage} />
                <Button icon='angle double right' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
            </Button.Group>
            <div>
                <span>Page {pageIndex + 1} from {pageCount}</span>
            </div>
        </div>
    );
}

export default BaseTableGlobalFilter;