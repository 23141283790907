import axios from "axios";
import getApiWithToken from "./api";  

export default async function getEndpoints() {
    const api = await getApiWithToken();

    return {
        getActivityTable: async () => {
            const response = await api.get('/activity');
            return response.data;
        },
        getZipRequestsTable: async () => {
            const response = await api.get('/ziprequests');
            return response.data;
        },
        getLookupsData: async () => {
            const response = await api.get('/lookups');
            return response.data;
        },
        getFileSearch: async (values) => {
            console.log(values);
            const response = await api.post('/filetable', values);
            return response.data;
        },
        postNewZipRequest: async (values) => {
            const response = await api.post('/ziprequests', values);
            return response.data;
        },
        deleteZipRequest: async (id) => {
            console.log(id);
            const response = await api.delete(`/ziprequests/${id}`);
            return response.data;
        },
        getZipFileDownload: async (id, setDownloadProgress, cancelToken) => {
            try {
                const response = await api.get(`/ziprequests/zipfile/${id}`, {
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                        if (progressEvent.lengthComputable) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setDownloadProgress(percentCompleted);
                        } else {
                            setDownloadProgress(0);
                        }
                    },
                    cancelToken: cancelToken // Passing the cancel token to the request
                });
                return response;
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Download request canceled:', error.message);
                } else {
                    console.error("Error in getTestZipFileDownload:", error.response ? error.response.status : error);
                }
            }
        },
        uploadBriefingDocumentForTranslation: async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            const response = await api.post('/BriefingDocuments', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            return response.data;
        },
        getBriefingDocuments: async () => {
            const response = await api.get(`/BriefingDocuments`);
            return response.data;
        },
        downloadBriefingDocument: async (documentId, documentKey) => {
            try {
                const response = await api.get(`/BriefingDocuments/download?documentId=${documentId}&documentKey=${documentKey}`, {
                    responseType: 'blob'
                });
                console.log('Response headers:', response.headers);
                console.log('Response type:', response.data.type);
                return response;
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Download request canceled:', error.message);
                } else {
                    console.error("Error in downloadTranslatedDocument:", error.response ? error.response.status : error);
                }
            }
        }
    };
}
